import { Intent } from "@blueprintjs/core";
import React from "react";
import styled from "styled-components";

import { HexTag } from "../../hex-components/HexTag.js";

interface ExploreAlphaTagProps {
  intent?: Intent;
}

export const ExploreAlphaTag: React.ComponentType<ExploreAlphaTagProps> =
  React.memo(function ExploreAlphaTag({
    intent = Intent.PRIMARY,
  }: {
    intent?: Intent;
  }) {
    return (
      <HexTag intent={intent} small={true}>
        Alpha
      </HexTag>
    );
  });

export const AlphaTextWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;
